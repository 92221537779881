import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const PROMOTIONS_MENU: MenuItem[] = [
  {
    id: 'promotions',
    title: 'Promotions',
    route: '/admin/promotions',
    icon: 'bullhorn',
    parentId: 'sales',
    position: 6,
    permissions: ['promotions_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(PROMOTIONS_MENU)],
})
export class PromotionsMenuModule {}

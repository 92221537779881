import { MapConfig } from '@x/common/map/map';

const POLYGON_THEME = {
  strokeColor: '#00479a',
  strokeOpacity: 0.1,
  strokeWeight: 1,
  fillColor: '#00479a',
  fillOpacity: 0.15,
};

// https://snazzymaps.com/editor/customize/151
const THEME = [
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#333333',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#d3d9e9',
      },
    ],
  },
];

const DEFAULT_ICON_SIZE = {
  width: 30,
  height: 45.48,
};
export const GOOGLE_MAPS_CONFIG: MapConfig = {
  markerIconUser: {
    url: 'https://res.cloudinary.com/babylon/image/upload/v1669905389/babylonstoren/map/user_marker2.svg',
    size: { width: 36, height: 36 },
  },
  markerIconRegion: {
    url: 'https://res.cloudinary.com/babylon/image/upload/v1662363103/babylonstoren/map/region_marker.svg',
    size: { ...DEFAULT_ICON_SIZE },
  },
  polygonTheme: POLYGON_THEME,
  theme: THEME,
  defaultCoordinates: {
    lat: -33.8239354,
    lng: 18.9237751,
  },
  iconMap: {
    'za-jhb': {
      url: 'https://res.cloudinary.com/babylon/image/upload/v1728544518/babylonstoren/map/JHB_PTA_wortel_solid_primary.svg.svg',
      size: { width: 88, height: 48 },
    },
    'za-cpt': {
      url: 'https://res.cloudinary.com/babylon/image/upload/v1675865819/babylonstoren/map/CPT_wortel_solid_primary.svg',
      size: { width: 30, height: 45.48 },
    },
  },
};
